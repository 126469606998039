import { connect } from "react-redux";
import { formatDateTime, mapStateToProps } from "../../utils";
import i18next from "../../i18n";
import { MessageResponse, UserResponse } from "../../types";

type MessagesProps = {
  user: UserResponse;
  conv?: MessageResponse;
  isBuy: boolean;
  isOrder?: boolean;
  isSeller: boolean;
  orderOwnerId?: string;
};

const Messages = ({
  user,
  conv,
  isBuy,
  isOrder,
  isSeller,
  orderOwnerId,
}: MessagesProps) => {
  let messageToDisplay = (
    <div className="chat-message">
      <div className="flex items-end">
        <div className="flex flex-col space-y-2 text-xs max-w-xs order-2 items-start">
          <div>
            {isBuy && isOrder && (
              <p className="text-xs uppercase font-bold">
                {conv?.user?.fullName} - {conv?.user?.company?.name}
              </p>
            )}
            {isSeller && orderOwnerId === conv?.user?.id && (
              <p className="text-xs uppercase font-bold">
                {conv?.user?.fullName} - {conv?.user?.company?.name}
              </p>
            )}
            {isOrder && isSeller && orderOwnerId !== conv?.user?.id && (
              <p className="text-xs uppercase font-bold">
                {i18next.t("chat.other")}
              </p>
            )}
            <div className="text-xxs text-gray-500">
              {formatDateTime(conv?.createdAt)}
            </div>
            <span className="px-4 py-2 rounded-lg inline-block rounded-bl-none bg-gray-500 text-white">
              {conv?.text}
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  if (user?.id === conv?.user?.id) {
    messageToDisplay = (
      <div className="chat-message">
        <div className="flex items-end justify-end">
          <div className="flex flex-col space-y-2 text-xs max-w-xs order-1 items-end">
            <div className="w-full">
              <div className="text-right text-xxs text-gray-500">
                {formatDateTime(conv?.createdAt)}
              </div>
              <span className="px-4 py-2 rounded-lg inline-block rounded-br-none bg-blue-600 text-white ">
                {conv?.text}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return messageToDisplay;
};

export default connect(mapStateToProps)(Messages);
