import { connect } from "react-redux";
import i18next from "../../i18n";
import { currencyFormatter, mapStateToProps } from "../../utils";
import { OfferResponse, OrderResponse } from "../../types";

const thIsBid = [
  { id: 1, name: i18next.t("tenders.tenderSummary.material") },
  { id: 2, name: i18next.t("tenders.tenderSummary.requestedVolume") },
  { id: 3, name: i18next.t("tenders.tenderSummary.awardedVolume") },
  { id: 4, name: i18next.t("tenders.tenderSummary.offeredPrice") },
  { id: 5, name: i18next.t("tenders.tenderSummary.awardedPrice") },
  { id: 6, name: i18next.t("tenders.tenderSummary.company") },
];

type BidResultTableProps = {
  title: string;
  isBuy: boolean;
  selectedOffers?: OfferResponse[];
  offer?: OfferResponse;
  ordersWithoutOffers?: OrderResponse[];
};

const BidResultTable = ({
  isBuy,
  title,
  selectedOffers,
  offer,
  ordersWithoutOffers,
}: BidResultTableProps) => {
  return (
    <div>
      <p className="text-green-800 py-1 font-bold mt-4 uppercase text-2xl">
        {title}
      </p>
      <div className="rounded p-2 shadow border-green-700 border-2">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              {thIsBid.map((t) => (
                <th
                  key={t.id}
                  scope="col"
                  className="uppercase py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6 md:pl-0"
                >
                  {t.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {selectedOffers &&
              selectedOffers?.map((offer) => {
                const oldPrice = currencyFormatter(
                  offer?.oldPrice,
                  offer?.order?.currency
                );

                const price = currencyFormatter(
                  offer?.price,
                  offer?.order?.currency
                );

                return (
                  <tr
                    key={offer?.order?.id}
                    className="border-b border-gray-200"
                  >
                    <td className="py-2 text-xs">
                      <div className="font-bold text-gray-900 uppercase text-xxs">
                        {offer?.order?.material?.globalMaterialName}
                      </div>
                    </td>
                    <td className="py-2 text-xs font-bold text-gray-600">
                      {offer?.order?.quantity} {offer?.order?.measurementUnit}
                    </td>
                    <td className="py-2 text-xs font-bold">
                      {offer?.quantity} {offer?.order?.measurementUnit}
                    </td>
                    <td className="py-2 text-xs font-bold text-gray-600">
                      {oldPrice}
                    </td>
                    <td className="py-2 text-xs font-bold">{price}</td>
                    <td className="py-2 text-xs font-bold">
                      {isBuy
                        ? offer?.user?.company?.name
                        : offer?.order?.user?.company?.name}
                    </td>
                  </tr>
                );
              })}
            {offer && (
              <>
                <tr key={offer?.order?.id} className="border-b border-gray-200">
                  <td className="py-2 text-xs">
                    <div className="font-medium text-gray-900 uppercase">
                      {offer?.order?.material?.globalMaterialName}
                    </div>
                  </td>
                  <td className="py-2 text-xs font-bold text-gray-600">
                    {offer?.order?.quantity} {offer?.order?.measurementUnit}
                  </td>
                  <td className="py-2 text-xs font-bold">
                    {offer?.quantity} {offer?.order?.measurementUnit}
                  </td>
                  <td className="py-2 text-xs font-bold text-gray-600">
                    {currencyFormatter(offer?.oldPrice, offer?.order?.currency)}
                  </td>
                  <td className="py-2 text-xs font-bold">
                    {currencyFormatter(offer?.price, offer?.order?.currency)}
                  </td>
                  <td className="py-2 text-xs font-bold">
                    {isBuy
                      ? offer?.user?.company?.name
                      : offer?.order?.user?.company?.name}
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>
      {ordersWithoutOffers && ordersWithoutOffers.length > 0 && (
        <>
          <p className="text-yellow-700 py-1 font-bold mt-4 uppercase text-lg">
            No Adjudicados
          </p>
          <div className="rounded p-2 shadow border-yellow-700 border-2">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  {thIsBid.map((t) => (
                    <th
                      key={t.id}
                      scope="col"
                      className="uppercase py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6 md:pl-0"
                    >
                      {t.name}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {ordersWithoutOffers?.map((order) => {
                  return (
                    <tr key={order?.id} className="border-b border-gray-200">
                      <td className="py-2 text-xs">
                        <div className="font-medium text-gray-900">
                          {order?.material?.globalMaterialName}
                        </div>
                      </td>
                      <td className="py-2 text-xs font-bold text-gray-600">
                        {order?.quantity} {order?.measurementUnit}
                      </td>
                      <td className="py-2 text-xs font-bold">-</td>
                      <td className="py-2 text-xs font-bold text-gray-600">
                        -
                      </td>
                      <td className="py-2 text-xs font-bold">-</td>
                      <td className="py-2 text-xs font-bold">-</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(BidResultTable);
