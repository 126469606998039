import { useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  Loader,
  PurchaseRequestsOrdersTable,
  PurchaseRequestsTable,
  Tabs,
} from "../../components";
import i18next from "../../i18n";
import { mapStateToProps } from "../../utils";
import {
  useCountPurchaseRequests,
  useMultiplePurchaseRequests,
  useMultiplePurchaseRequestsByState,
  usePurchaseRequestOrders,
  useUserPurchaseRequests,
  useUserPurchaseRequestsByState,
} from "../../hooks/usePurchaseRequests";

const PurchaseRequests = ({ isRequester }: { isRequester: boolean }) => {
  const [page, setPage] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);
  const [multiplePage, setMultiplePage] = useState(0);
  const [purchaseRequestOrdersPage, setPurchaseRequestOrdersPage] = useState(0);

  const { data: multiplePurchaseRequests, isLoading: isLoadingMultiple } =
    useMultiplePurchaseRequests(multiplePage, isRequester);

  const { data: userPurchaseRequests, isLoading: isLoadingUser } =
    useUserPurchaseRequests(page, isRequester);

  const { data: countPurchaseRequests, isLoading: isLoadingCount } =
    useCountPurchaseRequests(isRequester);

  const {
    data: userPurchaseRequestsByState,
    isLoading: isLoadingUserPurchase,
  } = useUserPurchaseRequestsByState(selectedTab, isRequester);

  const { data: multiplePurchaseRequestsByState, isLoading: isLoadingByState } =
    useMultiplePurchaseRequestsByState(selectedTab, isRequester);

  const {
    data: purchaseRequestOrders,
    isLoading: isLoadingPurchaseRequestOrders,
  } = usePurchaseRequestOrders(purchaseRequestOrdersPage);

  const tabs = [
    {
      name: i18next.t("purchaseRequests.tabs.pending"),
      current: false,
      bgColor: "bg-yellow-100 text-yellow-800",
      count: purchaseRequestOrders?.totalElements || 0,
    },
    {
      name: i18next.t("purchaseRequests.tabs.sent"),
      current: true,
      bgColor: "bg-blue-100 text-blue-800",
      count: countPurchaseRequests?.delivered || 0,
    },
    {
      name: i18next.t("purchaseRequests.tabs.draft"),
      current: false,
      bgColor: "bg-gray-100 text-gray-800",
      count: countPurchaseRequests?.draft || 0,
    },
    {
      name: i18next.t("purchaseRequests.tabs.inProgress"),
      current: false,
      bgColor: "bg-purple-100 text-purple-800",
      count: countPurchaseRequests?.open || 0,
    },
    {
      name: i18next.t("purchaseRequests.tabs.rejected"),
      current: false,
      bgColor: "bg-red-100 text-red-800",
      count: countPurchaseRequests?.rejected || 0,
    },
    {
      name: i18next.t("purchaseRequests.tabs.closed"),
      current: false,
      bgColor: "bg-green-100 text-green-800",
      count: countPurchaseRequests?.succeed || 0,
    },
  ];

  return (
    <div className="px-4 mt-8">
      <div className="md:flex items-center justify-between mb-6">
        <h2 className="text-xl font-bold leading-7 text-gray-900 sm:truncate uppercase">
          {i18next.t("purchaseRequests.title")}
        </h2>
        {isRequester && (
          <div className="flex gap-3 items-center">
            <Button link variant="add" to={"/import-purchase-request"}>
              {i18next.t("purchaseRequestOrders.title")}
            </Button>
            <Button link to={"/new-purchase-request"}>
              {i18next.t("purchaseRequests.add")}
            </Button>
          </div>
        )}
      </div>
      {isRequester && (
        <Tabs
          tabs={tabs}
          handleTabClick={setSelectedTab}
          selectedTab={selectedTab}
        />
      )}
      {isLoadingByState ||
      isLoadingCount ||
      isLoadingMultiple ||
      isLoadingUser ||
      isLoadingUserPurchase ||
      isLoadingPurchaseRequestOrders ? (
        <Loader />
      ) : (
        <div>
          {isRequester && (
            <>
              {selectedTab === 0 &&
                purchaseRequestOrders &&
                purchaseRequestOrders?.totalElements > 0 && (
                  <PurchaseRequestsOrdersTable
                    pageNumber={purchaseRequestOrdersPage}
                    setPageNumber={setPurchaseRequestOrdersPage}
                    purchaseRequestsOrders={purchaseRequestOrders}
                  />
                )}
              {selectedTab > 0 && (
                <>
                  {userPurchaseRequestsByState?.totalElements > 0 && (
                    <PurchaseRequestsTable
                      pageNumber={page}
                      setPageNumber={setPage}
                      purchaseRequests={userPurchaseRequestsByState}
                    />
                  )}

                  {multiplePurchaseRequestsByState?.totalElements > 0 && (
                    <PurchaseRequestsTable
                      title={`${i18next.t("purchaseRequests.joint")}`}
                      pageNumber={multiplePage}
                      setPageNumber={setMultiplePage}
                      multiplePurchaseRequests={multiplePurchaseRequestsByState}
                    />
                  )}

                  {userPurchaseRequestsByState?.totalElements === 0 &&
                    multiplePurchaseRequestsByState?.totalElements === 0 &&
                    selectedTab >= 1 && (
                      <p className="text-xs mt-10">
                        {i18next.t("purchaseRequests.noPurchaseRequests")}
                      </p>
                    )}
                </>
              )}
            </>
          )}

          {!isRequester && (
            <>
              {userPurchaseRequests?.totalElements > 0 && (
                <PurchaseRequestsTable
                  pageNumber={page}
                  setPageNumber={setPage}
                  purchaseRequests={userPurchaseRequests}
                />
              )}

              {multiplePurchaseRequests?.totalElements > 0 && (
                <PurchaseRequestsTable
                  title={`${i18next.t("purchaseRequests.joint")}`}
                  pageNumber={multiplePage}
                  setPageNumber={setMultiplePage}
                  multiplePurchaseRequests={multiplePurchaseRequests}
                />
              )}

              {userPurchaseRequests?.totalElements === 0 &&
                multiplePurchaseRequests?.totalElements === 0 && (
                  <p className="text-xs mt-10">
                    {i18next.t("purchaseRequests.noPurchaseRequests")}
                  </p>
                )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(PurchaseRequests);
