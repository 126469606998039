import { Fragment, useState, useEffect, useReducer } from "react";
import { connect } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import i18next from "../../i18n";
import axios from "../../axios-orders";
import {
  mapStateToProps,
  mapDispatchToProps,
  BIDDING_TYPES,
  BIDDING_TYPES_LABELS,
  PN_TYPE,
  MATERIAL_TYPE,
} from "../../utils";
import { Steps, Search, Loader, Modal, Pagination } from "../../components";
import BiddingTypeSelector from "./BiddingTypeSelector/BiddingTypeSelector";
import MaterialsCheckboxList from "./MaterialsCheckboxList/MaterialsCheckboxList";
import SuppliersCheckboxList from "./SuppliersCheckboxList/SuppliersCheckboxList";
import PriceDecrementPayment from "./PriceDecrementPayment/PriceDecrementPayment";
import MultipleMaterialsBiddingForm from "./MultipleMaterialsBiddingForm/MultipleMaterialsBiddingForm";
import MultipleBiddingSummary from "./MultipleBiddingSummary/MultipleBiddingSummary";
import { useDebounce, useFileInputUploader } from "../../hooks";
import JointSuppliersCheckboxList from "./JointSuppliersCheckboxList/JointSuppliersCheckboxList";
import {
  DELIVERY_TYPE,
  MaterialType,
  OrdersTuSumbitRequest,
  UserResponse,
} from "../../types";
import {
  useMaterial,
  useMaterials,
  useSearchMaterials,
} from "../../hooks/useMaterials";
import { useCreateMultipleOrder, useCreateOrder } from "../../hooks/useOrders";
import { queryKeys } from "../../hooks/queryKeys";
import { useInvitedSuppliers } from "../../hooks/useSuppliers";

const actions = {
  UPDATE_INTERNAL_CODE: "UPDATE_INTERNAL_CODE",
  UPDATE_PAYMENT_METHOD: "UPDATE_PAYMENT_METHOD",
  UPDATE_PAYMENT_TERM: "UPDATE_PAYMENT_TERM",
  UPDATE_START_DATE: "UPDATE_START_DATE",
  UPDATE_FINISH_DATE: "UPDATE_FINISH_DATE",
  UPDATE_EXPIRE_DATE: "UPDATE_EXPIRE_DATE",
  UPDATE_COMMENT: "UPDATE_COMMENT",
  UPDATE_NAME: "UPDATE_NAME",
  ADD_FILE: "ADD_FILE",
  REMOVE_FILE: "REMOVE_FILE",
};

export type File = {
  fileName: string;
  type: string;
  fileURL: string;
};

const types = [
  {
    id: 1,
    title: BIDDING_TYPES_LABELS.NORMAL,
    value: BIDDING_TYPES.NORMAL,
    description: i18next.t("tenders.normal"),
  },
  {
    id: 2,
    title: BIDDING_TYPES_LABELS.INVERSE,
    value: BIDDING_TYPES.INVERSE,
    description: i18next.t("tenders.inverse"),
  },
];

const complexTypes = [
  {
    id: 1,
    title: BIDDING_TYPES_LABELS.SIMPLE_OR_MULTIPLE,
    value: BIDDING_TYPES.NORMAL,
    description: i18next.t("tenders.simpleOrMultiple"),
  },
  {
    id: 2,
    title: BIDDING_TYPES_LABELS.JOINT,
    value: BIDDING_TYPES.JOINT,
    description: i18next.t("tenders.joint"),
  },
];

const initalState = {
  paymentMethod: "",
  paymentTerm: "",
  internalCode: "",
  startDateValue: "",
  finishDate: "",
  expireDate: false,
  comment: "",
  files: [] as File[],
};

type OrderProps = {
  user: UserResponse;
  onOpenNotification: (message: string) => void;
  onCleanUserInvites: any;
  onCreateOrderInvitation: any;
  orderInvitation: any;
};

const Order = ({
  user,
  onOpenNotification,
  onCleanUserInvites,
  onCreateOrderInvitation,
  orderInvitation,
}: OrderProps) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [searchMaterialsPage, setSearchMaterialsPage] = useState(0);
  const [materialsForBiddingPage, setMaterialsForBiddingPage] = useState(0);
  const [materialType, setMaterialType] = useState<MaterialType>();
  const [ordersToSubmit, setOrdersToSubmit] = useState<OrdersTuSumbitRequest[]>(
    []
  );
  const [generalBidData, setGeneralBidData] = useReducer(
    (state: any, action: any) => {
      let newEvent = { ...state };
      switch (action.type) {
        case actions.UPDATE_NAME:
          newEvent = {
            ...newEvent,
            name: action.name,
          };
          break;
        case actions.UPDATE_INTERNAL_CODE:
          newEvent = {
            ...newEvent,
            internalCode: action.internalCode,
          };
          break;
        case actions.UPDATE_START_DATE:
          newEvent = {
            ...newEvent,
            startDateValue: action.startDateValue,
            endDateValue: "",
          };
          break;
        case actions.UPDATE_FINISH_DATE:
          newEvent = {
            ...newEvent,
            endDateValue: action.endDateValue,
          };
          break;
        case actions.UPDATE_EXPIRE_DATE:
          newEvent = {
            ...newEvent,
            expireDate: action.expireDate,
          };
          break;
        case actions.UPDATE_PAYMENT_METHOD:
          newEvent = {
            ...newEvent,
            paymentMethod: action.paymentMethod,
          };
          break;
        case actions.UPDATE_PAYMENT_TERM:
          newEvent = {
            ...newEvent,
            paymentTerm: action.paymentTerm,
          };
          break;
        case actions.UPDATE_COMMENT:
          newEvent = {
            ...newEvent,
            comment: action.comment,
          };
          break;
        case actions.ADD_FILE:
          newEvent = {
            ...newEvent,
            files: [...newEvent.files, ...action.files],
          };
          break;
        case actions.REMOVE_FILE:
          newEvent = {
            ...newEvent,
            files: newEvent.files.filter(
              (_: any, index: number) => index !== action.index
            ),
          };
          break;
      }
      return newEvent;
    },
    initalState
  );
  const [selectedType, setSelectedType] = useState<any>(null);
  const [selectedComplexType, setSelectedComplexType] = useState<any>(null);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [open, setOpen] = useState(false);
  const [steps, setSteps] = useState([
    { id: 1, name: i18next.t("tenders.biddingType"), status: "current" },
    { id: 2, name: i18next.t("tenders.selectMaterials"), status: "upcoming" },
    { id: 3, name: i18next.t("tenders.selectSuppliers"), status: "upcoming" },
    { id: 4, name: i18next.t("tenders.generalData"), status: "upcoming" },
    { id: 5, name: i18next.t("tenders.summary"), status: "upcoming" },
  ]);
  const [searchTerm, setSearchTerm] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [activeStep, setActiveStep] = useState(1);
  const [disabled, setDisabled] = useState(true);

  const { debouncedSearchTerm } = useDebounce(searchTerm, 1000);
  const {
    uploadedSpecificationFileUrl,
    handleSpecificationUpload,
    isLoadingUpload,
  } = useFileInputUploader();

  const materialId = searchParams.get("id");

  const { mutate: onGetBiddingSuppliers, data: suppliers } = useMutation({
    mutationFn: (materialIds) =>
      axios.post(`/buyerSeller/materials`, materialIds),
  });

  const { data: material } = useMaterial(materialId || "");

  const { mutate: getInvitedSuppliers, data: invitedSuppliers } =
    useInvitedSuppliers();

  const orderInvitationHandler = (isMultiple: boolean, data: any) => {
    if (orderInvitation.length === 0) return;
    if (isMultiple) {
      onCreateOrderInvitation(orderInvitation[0].emailsList, data[0].id);
    } else {
      onCreateOrderInvitation(orderInvitation[0].emailsList, data.id);
    }
    onCleanUserInvites();
  };

  const { mutate: createOrder, isPending: isCreatingOrder } = useCreateOrder(
    (res) => {
      onOpenNotification(i18next.t("tenders.success"));
      orderInvitationHandler(false, res);
      return navigate("/orders");
    },
    () => onOpenNotification(i18next.t("newMaterialForm.error"))
  );

  const { mutate: createMultipleOrder, isPending: isCreatingMultipleOrder } =
    useCreateMultipleOrder(
      (res) => {
        onOpenNotification(i18next.t("tenders.success"));
        orderInvitationHandler(true, res);
        return navigate("/orders");
      },
      () => onOpenNotification(i18next.t("newMaterialForm.error"))
    );

  const { data: foundMaterials } = useSearchMaterials(
    debouncedSearchTerm,
    searchMaterialsPage
  );

  const { data: materials, isLoading: isLoadingMaterials } = useMaterials(
    queryKeys.MATERIALS,
    materialType as MaterialType,
    materialsForBiddingPage
  );

  const { handleSubmit, reset } = useForm();

  useEffect(() => {
    if (material && ordersToSubmit.length === 0) {
      ordersToSubmit.push({
        id: material.id,
        ean: material.ean,
        coin: material.coin,
        incoTerm: material.incoTerm,
        measurementUnit: material.measurementUnit,
        globalMaterialName: material.globalMaterialName,
        deliveryPlace: material.deliveryPlace,
        fileUrl: material.fileURL,
        type: material.type,
        spot: DELIVERY_TYPE.SPOT,
        suppliers: [],
      });
      setMaterialType(material.type);
    }
  }, [material]);

  const handleNext = (step: number) => {
    if (step === 1) {
      setActiveStep((prevState: any) => prevState + 1);
      setDisabled(true);
    }
    if (activeStep === 2) {
      setActiveStep((prevState: any) => prevState + 1);
      setDisabled(true);
      const mat = ordersToSubmit?.map((mat: any) => mat.id);
      onGetBiddingSuppliers(mat as any);
      getInvitedSuppliers(mat);
    }
    if (activeStep === 3) setActiveStep((prevState: any) => prevState + 1);
    if (activeStep === 4) setActiveStep((prevState: any) => prevState + 1);
    if (activeStep === 5) setActiveStep((prevState: any) => prevState + 1);
    if (activeStep === 6) setOpen(true);
  };

  const handleCheckboxChange = (material: any) => {
    const indexOfMaterial = ordersToSubmit.findIndex(
      (mat: any) => material.id === mat.id
    );
    if (indexOfMaterial === -1) {
      setOrdersToSubmit([
        ...ordersToSubmit,
        {
          id: material.id,
          ean: material.ean,
          coin: material.coin,
          incoTerm: material.incoTerm,
          measurementUnit: material.measurementUnit,
          globalMaterialName: material.globalMaterialName,
          deliveryPlace: material.deliveryPlace,
          fileUrl: material.fileURL,
          type: material.type,
          spot: DELIVERY_TYPE.SPOT,
          suppliers: [],
        },
      ]);
      setDisabled(true);
    } else {
      const slicedOrder = [
        ...ordersToSubmit.slice(0, indexOfMaterial),
        ...ordersToSubmit.slice(indexOfMaterial + 1),
      ];

      setOrdersToSubmit(slicedOrder);
      setDisabled(false);
    }
  };

  const handleSupplierSelection = (supplier: any, materialId: string) => {
    setIsAllSelected(false);
    const foundOrder = ordersToSubmit.find(
      (order: any) => order.id === materialId
    );
    const indexOfSupplier = foundOrder?.suppliers.findIndex(
      (sup: any) => supplier.seller.id === sup.id
    );
    if (indexOfSupplier === -1) {
      const ordIndex = ordersToSubmit.findIndex(
        (order: any) => order.id === materialId
      );
      setOrdersToSubmit((prevState: OrdersTuSumbitRequest[]) => {
        const newState = prevState.map((item: any, index: number) => {
          if (index === ordIndex) {
            return {
              ...item,
              suppliers: [...item.suppliers, { id: supplier.seller.id }],
            };
          }
          return item;
        });
        return newState;
      });
    } else {
      setOrdersToSubmit((prevState: OrdersTuSumbitRequest[]) => {
        const ordIndex = ordersToSubmit.findIndex(
          (order: any) => order.id === materialId
        );
        const newState = prevState.map((item: any, index: number) => {
          if (index === ordIndex) {
            return {
              ...item,
              suppliers: item.suppliers.filter(
                (sup: any) => sup.id != supplier.seller.id
              ),
            };
          }
          return item;
        });
        return newState;
      });
    }
  };

  const handleJointSupplierSelection = (supplier: any) => {
    setIsAllSelected(false);
    setOrdersToSubmit((prevState: OrdersTuSumbitRequest[]) =>
      prevState.map((order: any) => {
        const supplierExists = order.suppliers.some(
          (sup: any) => sup.id === supplier.id
        );
        return {
          ...order,
          suppliers: supplierExists
            ? order.suppliers.filter((sup: any) => sup.id !== supplier.id)
            : [...order.suppliers, { id: supplier.id }],
        };
      })
    );
  };

  const handleOrdersToSubmit = (
    invite: { emailsList: string[]; materialId: string },
    isJoint?: any
  ) => {
    if (isJoint) {
      setOrdersToSubmit((prevState: OrdersTuSumbitRequest[]) => {
        const newState = prevState.map((item: any) => {
          const updatedSuppliers = item.suppliers.filter(
            (supplier: any) => supplier.emailsList !== invite.emailsList
          );

          const emailExists = updatedSuppliers.length !== item.suppliers.length;

          return {
            ...item,
            suppliers: emailExists
              ? updatedSuppliers
              : [
                  ...item.suppliers,
                  { emailsList: invite.emailsList, materialId: item.id },
                ],
          };
        });
        return newState;
      });
    } else {
      const ordIndex = ordersToSubmit.findIndex(
        (order: any) => order.id === invite.materialId
      );
      setOrdersToSubmit((prevState: OrdersTuSumbitRequest[]) => {
        const newState = prevState.map((item: any, index: number) => {
          if (index === ordIndex) {
            const updatedSuppliers = item.suppliers.filter(
              (supplier: any) => supplier.emailsList !== invite.emailsList
            );

            const emailExists =
              updatedSuppliers.length !== item.suppliers.length;

            return {
              ...item,
              suppliers: emailExists
                ? updatedSuppliers
                : [...item.suppliers, invite],
            };
          }
          return item;
        });
        return newState;
      });
    }
  };

  const handleSelectAll = () => {
    if (isAllSelected) {
      setOrdersToSubmit((prevState: OrdersTuSumbitRequest[]) => {
        const newState = prevState.map((item: any) => {
          return {
            ...item,
            suppliers: [],
          };
        });
        return newState;
      });
    } else {
      setOrdersToSubmit((prevState: OrdersTuSumbitRequest[]) => {
        const newState = prevState.map((item: any, index: any) => {
          const sups = suppliers?.data[index].map((sup: any) => {
            return { id: sup.seller.id };
          });
          return {
            ...item,
            suppliers: sups,
          };
        });
        return newState;
      });
    }
    setIsAllSelected((prevState: any) => !prevState);
  };

  const handleSelectJointAll = () => {
    if (!suppliers?.data) return;
    const suppliersObject = Object.keys(suppliers?.data).map((key) => {
      return suppliers.data[key];
    });

    function getUniqueSuppliers(data: any) {
      const uniqueSuppliers = new Map();

      data.forEach((group: any) => {
        group.forEach((entry: any) => {
          const supplier = entry.seller;
          if (supplier && supplier.id) {
            uniqueSuppliers.set(supplier.id, supplier);
          }
        });
      });

      return Array.from(uniqueSuppliers.values());
    }

    const suppliersToShowList = getUniqueSuppliers(suppliersObject);

    if (isAllSelected) {
      setOrdersToSubmit((prevState: OrdersTuSumbitRequest[]) => {
        const newState = prevState.map((item: any) => {
          return {
            ...item,
            suppliers: [],
          };
        });
        return newState;
      });
    } else {
      setOrdersToSubmit((prevState: OrdersTuSumbitRequest[]) => {
        const newState = prevState.map((item: any) => {
          const sups = suppliersToShowList.map((sup) => {
            return { id: sup.id };
          });
          return {
            ...item,
            suppliers: sups,
          };
        });
        return newState;
      });
    }
    setIsAllSelected((prevState: any) => !prevState);
  };

  const handlePriceDecrementChanges = (
    e: React.ChangeEvent<HTMLInputElement>,
    material: any,
    key: any
  ) => {
    setErrorMessage(null);
    setOrdersToSubmit((prevState: OrdersTuSumbitRequest[]) => {
      const newState = prevState.map((mat: any) => {
        if (mat.id === material.id) {
          return { ...mat, [key]: e.target.value };
        }
        return mat;
      });
      return newState;
    });
  };

  const handleSimpleSupplyPeriod = (
    e: React.ChangeEvent<HTMLInputElement>,
    matToBid: any,
    type: any
  ) => {
    setOrdersToSubmit((prevState: OrdersTuSumbitRequest[]) => {
      const newState = prevState.map((mat: any) => {
        if (
          mat.id === matToBid.id &&
          type === "start" &&
          mat.spot === DELIVERY_TYPE.SPOT
        ) {
          return {
            ...mat,
            startSupplyDate: e.target.value,
            endSupplyDate: e.target.value,
          };
        } else if (mat.id === matToBid.id && type === "start") {
          return {
            ...mat,
            startSupplyDate: e.target.value,
            endSupplyDate: "",
          };
        } else if (mat.id === matToBid.id && type === "end") {
          return { ...mat, endSupplyDate: e.target.value };
        }
        if (mat.id === matToBid.id && type === DELIVERY_TYPE.SPOT) {
          return {
            ...mat,
            spot: e.target.value,
            startSupplyDate: mat.startSupplyDate,
            endSupplyDate: mat.startSupplyDate,
          };
        }
        return mat;
      });
      return newState;
    });
  };

  const handleSupplyPeriod = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: any
  ) => {
    const newValue = e.target.value;

    setOrdersToSubmit((prevState: OrdersTuSumbitRequest[]) => {
      const newState = prevState.map((mat: any) => {
        if (type === "start" && mat.spot === DELIVERY_TYPE.SPOT) {
          return {
            ...mat,
            startSupplyDate: newValue,
            endSupplyDate: newValue,
          };
        } else if (type === "start") {
          return {
            ...mat,
            startSupplyDate: newValue,
            endSupplyDate: "",
          };
        } else if (type === "end") {
          return { ...mat, endSupplyDate: newValue };
        } else if (type === DELIVERY_TYPE.SPOT) {
          return {
            ...mat,
            spot: newValue,
            startSupplyDate: mat.startSupplyDate,
            endSupplyDate: mat.startSupplyDate,
          };
        }
        return mat;
      });

      return newState;
    });
  };

  const handleSpecification = (e: any) => {
    const file = e?.target?.files;
    if (file) {
      handleSpecificationUpload(file);
    }
  };

  const onSubmit = () => {
    const dataToSubmit = ordersToSubmit.map((mat: any) => {
      const filteredSuppliers = mat?.suppliers.filter((sup: any) => sup.id);
      return {
        paymentMethod: generalBidData.paymentMethod,
        startDate: new Date(generalBidData.startDateValue).toISOString(),
        finishDate: new Date(generalBidData.endDateValue).toISOString(),
        quantity: parseFloat(mat.quantity),
        material: { id: mat.id },
        user_id: user?.id,
        email: user?.email,
        files: generalBidData.files,
        plicationUrl: "",
        deliveryPlace: mat?.deliveryPlace
          ? { id: mat?.deliveryPlace?.id }
          : null,
        fullname: user?.fullName,
        application: mat?.application,
        purpose: mat?.purpose,
        name: generalBidData.name ?? mat?.name,
        fileUrl: mat?.fileUrl,
        measurementUnit: mat?.measurementUnit,
        incoTerm: mat?.incoTerm,
        currency: mat?.coin,
        spot: mat?.spot === DELIVERY_TYPE.SPOT ? true : false,
        startSupplyDate: new Date(mat?.startSupplyDate).toISOString(),
        endSupplyDate: new Date(mat?.endSupplyDate).toISOString(),
        expireDate: generalBidData?.expireDate,
        type:
          selectedType.value === BIDDING_TYPES.INVERSE &&
          selectedComplexType.value === BIDDING_TYPES.JOINT
            ? BIDDING_TYPES.INVERSE_JOINT
            : selectedComplexType.value === BIDDING_TYPES.JOINT
              ? BIDDING_TYPES.JOINT
              : selectedType.value,
        internalCode: mat?.internalCode
          ? mat?.internalCode
          : generalBidData.internalCode,
        comment: generalBidData.comment,
        suppliers: filteredSuppliers,
        initialPrice:
          selectedType.value === BIDDING_TYPES.INVERSE
            ? parseFloat(mat.initialPrice)
            : null,
        minimumDecrement:
          selectedType.value === BIDDING_TYPES.INVERSE
            ? parseFloat(mat.minimumDecrement)
            : null,
        paymentTerm: parseInt(generalBidData.paymentTerm),
      };
    });
    dataToSubmit.length > 1
      ? createMultipleOrder(dataToSubmit)
      : createOrder(dataToSubmit[0]);
    setOpen(false);
    reset();
  };
  const onError = () => setOpen(false);

  const updateStepsStatus = () => {
    let objIndex = steps.findIndex((obj) => obj.id === activeStep);
    setSteps((prevState: any) => {
      const newState = prevState.map((step: any) => {
        if (step.id === objIndex + 1) {
          return { ...step, status: "current" };
        }
        if (step.id > objIndex + 1) {
          return { ...step, status: "upcoming" };
        }
        if (activeStep === 6) {
          return { ...step, status: "complete" };
        }
        return { ...step, status: "complete" };
      });
      return newState;
    });
    if (activeStep === 6) {
      setSteps((prevState: any) => {
        const newState = prevState.map((step: any) => ({
          ...step,
          status: "complete",
        }));
        return newState;
      });
    }
  };

  useEffect(() => {
    updateStepsStatus();
  }, [activeStep]);

  const verifyThirdStep = () => {
    const initialPrice = ordersToSubmit?.every(
      (el: any) => el.initialPrice > 0
    );
    const quantity = ordersToSubmit?.every((el: any) => el.quantity > 0);
    const name = ordersToSubmit?.every((el: any) => el.name);
    const startSupplyDate = ordersToSubmit?.every(
      (el: any) => el.startSupplyDate
    );
    const endSupplyDate = ordersToSubmit?.every((el: any) => el.endSupplyDate);
    const purpose = ordersToSubmit
      ?.filter((el: any) => el.type === PN_TYPE.SERVICE)
      ?.every((el: any) => el.purpose);
    const isService =
      ordersToSubmit?.filter((el: any) => el.type === PN_TYPE.SERVICE)?.length >
      0;
    const minimumDecrement = ordersToSubmit?.every(
      (el: any) => el.minimumDecrement > 0
    );
    if (selectedType?.value === BIDDING_TYPES.NORMAL) {
      if (isService) {
        name && purpose && startSupplyDate && endSupplyDate
          ? setDisabled(false)
          : setDisabled(true);
      } else {
        quantity && name && startSupplyDate && endSupplyDate
          ? setDisabled(false)
          : setDisabled(true);
      }
    }
    if (selectedType?.value === BIDDING_TYPES.INVERSE) {
      if (isService) {
        name && initialPrice && minimumDecrement && purpose
          ? setDisabled(false)
          : setDisabled(true);
      } else {
        name && quantity && initialPrice && minimumDecrement
          ? setDisabled(false)
          : setDisabled(true);
      }
    }
    if (selectedComplexType?.value === BIDDING_TYPES.JOINT) {
      if (selectedType?.value === BIDDING_TYPES.INVERSE) {
        quantity && initialPrice && minimumDecrement
          ? setDisabled(false)
          : setDisabled(true);
      } else {
        quantity ? setDisabled(false) : setDisabled(true);
      }
    }
  };

  useEffect(() => {
    const startSupplyDate = ordersToSubmit?.every(
      (el: any) => el.startSupplyDate
    );
    const endSupplyDate = ordersToSubmit?.every((el: any) => el.endSupplyDate);
    const name = ordersToSubmit?.every((el: any) => el.name);
    if (activeStep === 1)
      selectedType !== null && selectedComplexType !== null
        ? setDisabled(false)
        : setDisabled(true);
    if (activeStep === 2) {
      if (selectedComplexType?.value === BIDDING_TYPES.JOINT) {
        ordersToSubmit?.length > 1 ? setDisabled(false) : setDisabled(true);
      } else {
        ordersToSubmit?.length > 0 ? setDisabled(false) : setDisabled(true);
      }
    }
    if (activeStep === 3) {
      ordersToSubmit?.every((el: any) => el.suppliers?.length > 0)
        ? setDisabled(false)
        : setDisabled(true);
    }
    if (activeStep === 4) verifyThirdStep();
    if (activeStep === 5)
      generalBidData.paymentMethod &&
      generalBidData.startDateValue &&
      generalBidData.endDateValue &&
      generalBidData.paymentTerm &&
      (generalBidData.name || name) &&
      startSupplyDate &&
      endSupplyDate
        ? setDisabled(false)
        : setDisabled(true);
  });

  return (
    <div className="px-4 mt-6">
      <h2 className="text-xl font-bold leading-7 text-gray-900 sm:truncate uppercase">
        {i18next.t("navigation.newTender")}
      </h2>
      {isCreatingOrder || isCreatingMultipleOrder ? (
        <div className="pt-8">
          <Loader />
        </div>
      ) : (
        <div className="mt-4 border p-4 rounded">
          <Steps steps={steps} />
          {activeStep === 1 && (
            <Fragment>
              <BiddingTypeSelector
                types={types}
                onChangeType={(e) => setSelectedType(e)}
                typeValue={selectedType}
              />
              {selectedType && (
                <BiddingTypeSelector
                  types={complexTypes}
                  onChangeType={(e) => setSelectedComplexType(e)}
                  typeValue={selectedComplexType}
                />
              )}
            </Fragment>
          )}
          {activeStep === 2 && (
            <Fragment>
              <Search
                value={searchTerm}
                handleSearch={(e) => setSearchTerm(e.target.value)}
                border
              />
              {errorMessage && (
                <span className="uppercase text-sm text-red-700 font-bold">
                  {errorMessage}
                </span>
              )}
              <div className="col-span-4 md:col-span-1">
                <label
                  htmlFor="type"
                  className="uppercase block text-xs font-medium text-gray-700"
                >
                  {i18next.t("newMaterialForm.type")}
                </label>
                <select
                  id="type"
                  name="type"
                  className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs rounded"
                  defaultValue=""
                  value={materialType}
                  onChange={(e) => {
                    setMaterialType(e.target.value as MaterialType);
                    setOrdersToSubmit([]);
                  }}
                >
                  <option value="">
                    {i18next.t("validations.selectType")}
                  </option>
                  {MATERIAL_TYPE?.map((type) => (
                    <option key={type.id} value={type.value}>
                      {type.name}
                    </option>
                  ))}
                </select>
              </div>
              {selectedComplexType.value === BIDDING_TYPES.JOINT &&
                ordersToSubmit.length < 2 &&
                materialType && (
                  <p className="p-2 bg-yellow-200 border-2 border-yellow-500 text-xs mt-4 rounded">
                    {i18next.t("tenders.selectMoreThanOne")}
                  </p>
                )}

              {isLoadingMaterials ? (
                <div className="pt-8">
                  <Loader />
                </div>
              ) : (
                <>
                  {materials &&
                    materials?.content?.length > 0 &&
                    searchTerm === "" && (
                      <>
                        <MaterialsCheckboxList
                          isChecked={ordersToSubmit}
                          materials={materials}
                          handleCheckboxChange={handleCheckboxChange}
                        />
                        <div className="w-full mt-5">
                          <Pagination
                            currentPage={materialsForBiddingPage}
                            setPageNumber={setMaterialsForBiddingPage}
                            results={materials.numberOfElements}
                            total={materials.totalElements}
                            amountOfPages={materials.totalPages}
                          />
                        </div>
                      </>
                    )}
                  {foundMaterials?.content && searchTerm !== "" && (
                    <>
                      <MaterialsCheckboxList
                        searchTerm={searchTerm}
                        isChecked={ordersToSubmit}
                        foundMaterials={foundMaterials}
                        handleCheckboxChange={handleCheckboxChange}
                      />
                      <div className="w-full mt-5">
                        <Pagination
                          currentPage={searchMaterialsPage}
                          setPageNumber={setSearchMaterialsPage}
                          results={foundMaterials.numberOfElements}
                          total={foundMaterials.totalElements}
                          amountOfPages={foundMaterials.totalPages}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </Fragment>
          )}
          {activeStep === 3 &&
            suppliers?.data &&
            selectedComplexType?.value !== BIDDING_TYPES.JOINT && (
              <div>
                {suppliers?.data.find((sup: any) => sup?.length > 0) && (
                  <div className="relative flex items-start pt-4 pb-2 border-b">
                    <div className="flex items-center gap-2">
                      <input
                        id="selectAll"
                        aria-describedby="comments-description"
                        name="selectAll"
                        type="checkbox"
                        checked={isAllSelected}
                        className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300 rounded"
                        onChange={handleSelectAll}
                      />
                      <label
                        htmlFor="selectAll"
                        className="font-bold text-xs text-spectum-dark uppercase"
                      >
                        {i18next.t("order.selectAll")}
                      </label>
                    </div>
                  </div>
                )}
                <SuppliersCheckboxList
                  invitedSuppliers={invitedSuppliers || []}
                  suppliers={suppliers?.data}
                  handleSupplierSelection={handleSupplierSelection}
                  ordersToSubmit={ordersToSubmit}
                  orderInvitation={orderInvitation}
                  handleOrdersToSubmit={handleOrdersToSubmit}
                />
              </div>
            )}
          {activeStep === 3 &&
            suppliers &&
            selectedComplexType?.value === BIDDING_TYPES.JOINT && (
              <div>
                {suppliers?.data.find((sup: any) => sup?.length > 0) && (
                  <div className="relative flex items-start pt-4 pb-2 border-b">
                    <div className="flex items-center gap-2">
                      <input
                        id="selectAll"
                        aria-describedby="comments-description"
                        name="selectAll"
                        type="checkbox"
                        checked={isAllSelected}
                        className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300 rounded"
                        onChange={handleSelectJointAll}
                      />
                      <label
                        htmlFor="selectAll"
                        className="font-bold text-xs text-spectum-dark uppercase"
                      >
                        {i18next.t("order.selectAll")}
                      </label>
                    </div>
                  </div>
                )}
                <JointSuppliersCheckboxList
                  invitedSuppliers={invitedSuppliers || []}
                  suppliers={suppliers?.data}
                  handleSupplierSelection={handleJointSupplierSelection}
                  ordersToSubmit={ordersToSubmit}
                  orderInvitation={orderInvitation}
                  handleOrdersToSubmit={handleOrdersToSubmit}
                />
              </div>
            )}
          {activeStep === 3 && !suppliers && (
            <div className="pt-8">
              <Loader />
            </div>
          )}
          {activeStep === 4 && (
            <PriceDecrementPayment
              type={
                selectedComplexType?.value === BIDDING_TYPES.JOINT &&
                selectedType?.value === BIDDING_TYPES.INVERSE
                  ? BIDDING_TYPES.INVERSE_JOINT
                  : selectedComplexType?.value === BIDDING_TYPES.JOINT &&
                      selectedType?.value === BIDDING_TYPES.NORMAL
                    ? BIDDING_TYPES.JOINT
                    : selectedType?.value
              }
              materialsToBid={ordersToSubmit}
              handlePriceDecrementChanges={handlePriceDecrementChanges}
              handleSupplyPeriod={handleSimpleSupplyPeriod}
            />
          )}
          {activeStep === 5 && (
            <MultipleMaterialsBiddingForm
              type={selectedComplexType.value}
              actions={actions}
              materialsToBid={ordersToSubmit}
              isLoadingUpload={isLoadingUpload}
              generalBidData={generalBidData}
              setGeneralBidData={setGeneralBidData}
              handleSpecification={handleSpecification}
              uploadedFileUrl={uploadedSpecificationFileUrl}
              handleSupplyPeriod={handleSupplyPeriod}
            />
          )}
          {activeStep === 6 && (
            <MultipleBiddingSummary
              orderSummary={ordersToSubmit}
              generalBidData={generalBidData}
              type={selectedType.value}
            />
          )}
          <div className="flex justify-end items-center gap-2 mt-8">
            <button
              className={`uppercase flex justify-center py-2 px-4 border border-transparent rounded shadow-sm text-xs font-medium ${
                activeStep === 1 ? "text-gray-400" : "text-gray-900"
              } bg-white`}
              onClick={() => setActiveStep((prevState: any) => prevState - 1)}
              disabled={activeStep === 1}
            >
              {i18next.t("cta.back")}
            </button>
            <button
              className={`inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-xs font-medium rounded ${
                disabled
                  ? "bg-white text-gray-400"
                  : "bg-spectum hover:bg-spectum-light text-white"
              } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light uppercase`}
              onClick={() => handleNext(activeStep)}
              disabled={disabled}
            >
              {activeStep === 6
                ? i18next.t("cta.launch")
                : i18next.t("cta.next")}
            </button>
          </div>
        </div>
      )}
      <Modal
        open={open}
        setOpen={setOpen}
        icon={
          <CheckCircleIcon
            className="h-6 w-6 text-green-600"
            aria-hidden="true"
          />
        }
        title={i18next.t("cta.launch")}
        message={i18next.t("order.question")}
        onClickSuccess={handleSubmit(onSubmit, onError)}
        onClickCancel={() => setOpen(false)}
      />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Order);
