import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ClipLoader from "react-spinners/ClipLoader";
import { CheckCircleIcon, PlusIcon } from "@heroicons/react/24/solid";
import {
  newMaterialValidationSchema,
  MEASUREMENT_UNIT_LIST,
  INCOTERM_LIST,
  CURRENCY_LIST,
  capitalize,
  NO_SPEC,
  TO_BE_AGREED,
  MATERIAL_TYPE,
  PN_TYPE,
  certificationSuggestions,
  CERTIFICATIONS,
  MEASUREMENT_UNIT_LIST_NPI,
} from "../../utils";
import { useDebounce, useFileInputUploader, useLanguage } from "../../hooks";
import i18next from "../../i18n";
import {
  Loader,
  Modal,
  InputField,
  Toggle,
  CategorySearch,
  SearchList,
} from "..";
import CategoriesBuilder from "./CategoriesBuilder/CategoriesBuilder";
import PnTypeSelect from "./PnTypeSelect/PnTypeSelect";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  CertificationRequest,
  CertificationsEnum,
  MaterialRequest,
  MaterialType,
  PN_TYPES,
  SiteResponse,
  UserResponse,
} from "../../types";
import {
  useCategoriesByParent,
  useCategoriesById,
  useSearchMaterialCategoryByName,
} from "../../hooks/useCategories";
import { useSites } from "../../hooks/useSites";
import { useCreateMaterial } from "../../hooks/useMaterials";

const pi_npi = [
  {
    id: PN_TYPES.PI,
    title: i18next.t("titles.productive"),
    value: PN_TYPES.PI,
  },
  {
    id: PN_TYPES.NPI,
    title: i18next.t("titles.nonProductive"),
    value: PN_TYPES.NPI,
  },
  {
    id: PN_TYPES.SERVICE,
    title: i18next.t("titles.services"),
    value: PN_TYPES.SERVICE,
  },
];

type NewMaterialFormProps = {
  user: UserResponse;
  onOpenNotification: (message: string) => void;
};

const NewMaterialForm = ({
  user,
  onOpenNotification,
}: NewMaterialFormProps) => {
  const { t } = useTranslation();
  const langEs = useLanguage();
  const navigate = useNavigate();
  const {
    uploadedSpecificationFileUrl,
    handleSpecificationUpload,
    isLoadingUpload,
  } = useFileInputUploader();
  const [searchTerm, setSearchTerm] = useState("");
  const [advancedSearch, setAdvancedSearch] = useState(false);
  const { debouncedSearchTerm, isSearching } = useDebounce(searchTerm, 1000);

  const [open, setOpen] = useState(false);
  const [pnType, setPnType] = useState("");
  const [buyTeamSelected, setBuyTeamSelected] = useState("");
  const [productServiceClassSelected, setProductServiceClassSelected] =
    useState("");
  const [productServiceFamilySelected, setProductServiceFamilySelected] =
    useState("");
  const [measurementUnitValue, setMeasurementUnitValue] = useState("");
  const [incotermValue, setIncotermValue] = useState("");
  const [deliveryPlaceId, setDeliveryPlaceId] = useState("");
  const [isSpecConfidential, setIsSpecConfidential] = useState(false);
  const [materialConfidential, setMaterialConfidential] = useState(false);
  const [addCertification, setAddCertification] = useState(false);
  const [certifications, setCertifications] = useState<CertificationRequest[]>(
    []
  );
  const [certificationType, setCertificationType] = useState<{
    type: CertificationsEnum;
    label: string;
  } | null>(null);
  const [certificationName, setCertificationName] = useState("");
  const [certificationRequired, setCertificationRequired] = useState(false);
  const [fileName, setFileName] = useState<any>(null);

  const { data: materialCategoryByName, isError } =
    useSearchMaterialCategoryByName(debouncedSearchTerm, langEs);

  const { data: sites } = useSites(user?.company?.id);

  const { data: buyTeams, isLoading: isLoadingBuyTeams } =
    useCategoriesByParent("buyTeams", pnType as MaterialType);

  const {
    data: productServiceFamily,
    isLoading: isLoadingProductServiceFamily,
  } = useCategoriesById("productServiceFamily", buyTeamSelected);

  const { data: productServiceClass, isLoading: isLoadingProductServiceClass } =
    useCategoriesById("productServiceClass", productServiceFamilySelected);

  const { mutate: createMaterial, isPending: isLoadingMaterials } =
    useCreateMaterial(
      () => {
        onOpenNotification(t("newMaterialForm.successMaterial"));
        setPnType("");
        reset();
        setFileName(null);
        cleaner();
        return navigate(`/materials`);
      },
      () => {
        onOpenNotification(t("newMaterialForm.error"));
        return navigate(`/materials`);
      }
    );

  const formValidationNeeded = newMaterialValidationSchema(pnType);

  const formOptions = {
    resolver: yupResolver(formValidationNeeded as any),
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<any>(formOptions);

  const cleaner = () => {
    setBuyTeamSelected("");
    setProductServiceFamilySelected("");
    setProductServiceClassSelected("");
  };

  const handleSpecification = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files;
    if (file) {
      handleSpecificationUpload(file);
      setFileName(file[0]?.name);
    }
  };

  const onAddCertification = () => {
    if (certificationType && certificationName) {
      setCertifications((prevCertifications: any) => [
        ...prevCertifications,
        {
          name: certificationName,
          type: certificationType.type,
          label: certificationType.label,
          required: certificationRequired,
        },
      ]);
      setCertificationType(null);
      setCertificationName("");
      setCertificationRequired(false);
    }
  };

  const onRemoveCertification = (index: number) => {
    setCertifications((prevCertifications) =>
      prevCertifications.filter((_, i) => i !== index)
    );
  };

  const onSubmit = (data: MaterialRequest) => {
    const name = capitalize(data.globalMaterialName) || "";
    const format = data.deliveryFormat ? capitalize(data.deliveryFormat) : "";
    const comments = data.comments && capitalize(data.comments, true);
    const application = data.application && capitalize(data.application, true);
    createMaterial({
      ...data,
      category: { id: productServiceClassSelected },
      deliveryPlace:
        deliveryPlaceId === TO_BE_AGREED ? null : { id: deliveryPlaceId },
      fileURL: uploadedSpecificationFileUrl
        ? uploadedSpecificationFileUrl
        : NO_SPEC,
      globalMaterialName: name,
      deliveryFormat: format,
      annualVolume: parseInt(String(data.annualVolume)),
      comments,
      application,
      confidentSpec: isSpecConfidential,
      confident: materialConfidential,
      certifications,
      type: pnType === PN_TYPES.SERVICE ? PN_TYPES.SERVICE : data.type,
    });
    setOpen(false);
  };
  const onError = () => setOpen(false);

  const handlePNChange = (value: PN_TYPES) => {
    setPnType(value);
    cleaner();
  };

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setAdvancedSearch(false);
  };

  const onChangeSearchList = (cat: any) => {
    const pnType = cat?.parent?.parent?.parent?.name;
    setPnType(pnType);
    const name = langEs ? cat?.name : cat?.nameEn;
    setSearchTerm(name);
    setProductServiceClassSelected(cat?.id);
  };

  if (isLoadingMaterials) return <Loader isFullScreen />;

  return (
    <Fragment>
      <form
        className="space-y-8 divide-y divide-gray-200 mt-8"
        autoComplete="off"
      >
        <div className="mt-10 sm:mt-0">
          <div className="mt-5 md:mt-0">
            <div className="shadow border overflow-hidden rounded p-2">
              <CategorySearch isError={isError} />

              <div className="grid grid-cols-4 gap-2">
                <SearchList
                  filteredCategories={materialCategoryByName}
                  searchTerm={searchTerm}
                  onChangeSearch={onChangeSearch}
                  onChangeSearchList={onChangeSearchList}
                  isSearching={isSearching}
                />
                <div className="col-span-4 md:col-span-1">
                  <button
                    className="w-full py-ten border border-spectum shadow-sm text-xs font-medium rounded text-gray-700 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light uppercase mr-4"
                    onClick={(e) => {
                      e.preventDefault();
                      setAdvancedSearch(!advancedSearch);
                      setProductServiceClassSelected("");
                    }}
                  >
                    {i18next.t("cta.advancedSearch")}
                  </button>
                </div>
              </div>

              {isSearching && (
                <div className="flex w-full items-center justify-center mt-6">
                  <ClipLoader loading size={30} />
                </div>
              )}

              {advancedSearch && (
                <>
                  <div className="grid grid-cols-4 gap-2 items-center mt-2">
                    <PnTypeSelect
                      pi_npi={pi_npi}
                      handlePNChange={handlePNChange}
                    />
                    <CategoriesBuilder
                      register={register}
                      buyTeams={buyTeams}
                      setBuyTeamSelected={setBuyTeamSelected}
                      setProductServiceFamilySelected={
                        setProductServiceFamilySelected
                      }
                      setProductServiceClassSelected={
                        setProductServiceClassSelected
                      }
                      errors={errors}
                      buyTeamSelected={buyTeamSelected}
                      isLoadingCategory={
                        isLoadingBuyTeams ||
                        isLoadingProductServiceClass ||
                        isLoadingProductServiceFamily
                      }
                      productServiceFamily={productServiceFamily}
                      productServiceClass={productServiceClass}
                      productServiceFamilySelected={
                        productServiceFamilySelected
                      }
                      productServiceClassSelected={productServiceClassSelected}
                    />
                  </div>
                </>
              )}

              <hr className="mb-10 mt-3" />
              <div className="grid sm:grid-cols-4 gap-2 bg-white mt-2">
                {productServiceClassSelected && (
                  <Fragment>
                    {pnType !== PN_TYPE.SERVICE && (
                      <div className="col-span-4 md:col-span-1">
                        <label
                          htmlFor="type"
                          className="uppercase block text-xs font-medium text-gray-700"
                        >
                          {t("newMaterialForm.type")}
                          <span className="text-sm text-red-600"> *</span>
                        </label>
                        <select
                          {...register("type", {
                            required: true,
                          })}
                          id="type"
                          name="type"
                          className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs rounded"
                          defaultValue=""
                        >
                          <option value="">
                            {t("validations.selectType")}
                          </option>
                          {MATERIAL_TYPE?.map((type) => (
                            <option key={type.id} value={type.value}>
                              {type.name}
                            </option>
                          ))}
                        </select>
                        {errors?.type && (
                          <span className="text-xs text-red-500">
                            {errors?.type?.message as any}
                          </span>
                        )}
                      </div>
                    )}

                    <div className="col-span-4 md:col-span-1">
                      <InputField
                        id="ean"
                        name="ean"
                        label={
                          pnType === PN_TYPE.SERVICE
                            ? t("newMaterialForm.eanService")
                            : t("newMaterialForm.ean")
                        }
                        errorName={errors?.ean}
                        errorMessage={errors?.ean?.message}
                        required
                        register={register}
                      />
                    </div>

                    <div className="col-span-4 md:col-span-1">
                      <InputField
                        id="globalMaterialName"
                        name="globalMaterialName"
                        label={
                          pnType === PN_TYPE.SERVICE
                            ? t("newMaterialForm.nameService")
                            : t("newMaterialForm.name")
                        }
                        errorName={errors?.globalMaterialName}
                        errorMessage={errors?.globalMaterialName?.message}
                        required
                        register={register}
                      />
                    </div>

                    {pnType !== PN_TYPE.SERVICE && (
                      <div className="col-span-4 md:col-span-1">
                        <InputField
                          id="annualVolume"
                          name="annualVolume"
                          type="number"
                          min={0}
                          defaultValue={0}
                          label={t("newMaterialForm.annualVolume")}
                          errorName={errors?.annualVolume}
                          errorMessage={errors?.annualVolume?.message}
                          register={register}
                        />
                      </div>
                    )}

                    {pnType === PN_TYPE.PI && (
                      <Fragment>
                        <div className="col-span-4 md:col-span-1">
                          <label
                            htmlFor="measurementUnit"
                            className="uppercase block text-xs font-medium text-gray-700"
                          >
                            {t("newMaterialForm.measurementUnit")}
                            <span className="text-sm text-red-600"> *</span>
                          </label>
                          <select
                            {...register("measurementUnit", {
                              required: true,
                            })}
                            id="measurementUnit"
                            name="measurementUnit"
                            className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs rounded"
                            defaultValue=""
                            onChange={(e) =>
                              setMeasurementUnitValue(e.target.value)
                            }
                          >
                            <option value="">
                              {t("newMaterialForm.selectMeasurementUnit")}
                            </option>
                            {MEASUREMENT_UNIT_LIST?.map((unit) => (
                              <option key={unit.id} value={unit.name}>
                                {unit.name}
                              </option>
                            ))}
                          </select>
                          {errors?.measurementUnit && !measurementUnitValue && (
                            <span className="text-xs text-red-500">
                              {errors?.measurementUnit?.message as string}
                            </span>
                          )}
                        </div>

                        {measurementUnitValue === "OTRO (ESPECIFICAR)" && (
                          <div className="col-span-4 md:col-span-1">
                            <InputField
                              id="measurementUnit"
                              name="measurementUnit"
                              type="text"
                              label={t("newMaterialForm.measurementUnit")}
                              errorName={errors?.measurementUnit}
                              errorMessage={errors?.measurementUnit?.message}
                              register={register}
                              required
                            />
                          </div>
                        )}

                        {pnType !== PN_TYPE.SERVICE && (
                          <div className="col-span-4 md:col-span-1">
                            <label
                              htmlFor="incoTerm"
                              className="uppercase block text-xs font-medium text-gray-700"
                            >
                              {t("newMaterialForm.incoTerm")}
                              <span className="text-sm text-red-600"> *</span>
                            </label>
                            <select
                              {...register("incoTerm", { required: true })}
                              id="incoTerm"
                              name="incoTerm"
                              className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs rounded"
                              defaultValue=""
                              onChange={(e) => setIncotermValue(e.target.value)}
                            >
                              <option value="">
                                {t("newMaterialForm.selectIncoTerm")}
                              </option>
                              {INCOTERM_LIST?.map((inco) => (
                                <option key={inco.id} value={inco.name}>
                                  {inco.name}
                                </option>
                              ))}
                            </select>
                            {errors?.incoTerm && !incotermValue && (
                              <span className="text-xs text-red-500">
                                {errors?.incoTerm?.message as any}
                              </span>
                            )}
                          </div>
                        )}

                        {incotermValue === "OTRO (ESPECIFICAR)" && (
                          <div className="col-span-4 md:col-span-1">
                            <InputField
                              id="incoTerm"
                              name="incoTerm"
                              type="text"
                              label={t("newMaterialForm.incoTerm")}
                              errorName={errors?.incoTerm as any}
                              errorMessage={errors?.incoTerm?.message as any}
                              register={register}
                              required
                            />
                          </div>
                        )}

                        <div className="col-span-4 md:col-span-1">
                          <InputField
                            id="deliveryFormat"
                            name="deliveryFormat"
                            type="text"
                            label={t("newMaterialForm.deliveryFormat")}
                            errorName={errors?.deliveryFormat as any}
                            errorMessage={
                              errors?.deliveryFormat?.message as any
                            }
                            register={register}
                            required
                          />
                        </div>
                      </Fragment>
                    )}

                    {pnType === PN_TYPE.NPI && (
                      <div className="col-span-4 md:col-span-1">
                        <label
                          htmlFor="measurementUnit"
                          className="uppercase block text-xs font-medium text-gray-700"
                        >
                          {t("newMaterialForm.measurementUnit")}
                          <span className="text-sm text-red-600"> *</span>
                        </label>
                        <select
                          {...register("measurementUnit", {
                            required: true,
                          })}
                          id="measurementUnit"
                          name="measurementUnit"
                          className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs rounded"
                          defaultValue=""
                          onChange={(e) =>
                            setMeasurementUnitValue(e.target.value)
                          }
                        >
                          <option value="">
                            {t("newMaterialForm.selectMeasurementUnit")}
                          </option>
                          {MEASUREMENT_UNIT_LIST_NPI?.map((unit) => (
                            <option key={unit.id} value={unit.name}>
                              {unit.name}
                            </option>
                          ))}
                        </select>
                        {errors?.measurementUnit && !measurementUnitValue && (
                          <span className="text-xs text-red-500">
                            {errors?.measurementUnit?.message as string}
                          </span>
                        )}
                      </div>
                    )}

                    <div className="col-span-4 md:col-span-1">
                      <label
                        htmlFor="deliveryPlace"
                        className="uppercase block text-xs font-medium text-gray-700"
                      >
                        {t("newMaterialForm.deliveryPlace")}
                        <span className="text-sm text-red-600"> *</span>
                      </label>
                      <select
                        {...register("deliveryPlace", { required: true })}
                        id="deliveryPlace"
                        name="deliveryPlace"
                        className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs rounded"
                        defaultValue=""
                        onChange={(e) => setDeliveryPlaceId(e.target.value)}
                      >
                        <option value="">
                          {t("newMaterialForm.selectDeliveryPlace")}
                        </option>
                        {sites?.map((site: SiteResponse) => (
                          <option key={site.id} value={site.id}>
                            {site.name}
                          </option>
                        ))}
                        <option value={TO_BE_AGREED}>{TO_BE_AGREED}</option>
                      </select>

                      {errors?.deliveryPlace && !deliveryPlaceId && (
                        <span className="text-xs text-red-500">
                          {errors?.deliveryPlace?.message as any}
                        </span>
                      )}
                    </div>

                    <div className="col-span-4 md:col-span-1">
                      <label
                        htmlFor="coin"
                        className="uppercase block text-xs font-medium text-gray-700"
                      >
                        {t("newMaterialForm.currency")}
                        <span className="text-sm text-red-600"> *</span>
                      </label>
                      <select
                        {...register("coin", { required: true })}
                        id="coin"
                        name="coin"
                        className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs rounded"
                        defaultValue=""
                      >
                        <option value="">
                          {t("newMaterialForm.selectCurrency")}
                        </option>
                        {CURRENCY_LIST?.map((coin) => (
                          <option key={coin.id} value={coin.name}>
                            {coin.name}
                          </option>
                        ))}
                      </select>

                      {errors?.coin && (
                        <span className="text-xs text-red-500">
                          {errors?.coin?.message as any}
                        </span>
                      )}
                    </div>

                    {pnType !== PN_TYPE.SERVICE && (
                      <div className="col-span-4 md:col-span-1 relative">
                        <InputField
                          id="application"
                          name="application"
                          type="text"
                          label={
                            pnType === PN_TYPE.PI
                              ? t("newMaterialForm.application")
                              : "Marca / PRODUCTO"
                          }
                          register={register}
                          isOptional
                        />
                      </div>
                    )}

                    <div className="col-span-4 md:col-span-1 flex items-center">
                      <Toggle
                        enabled={materialConfidential}
                        onChange={() =>
                          setMaterialConfidential((prevState) => !prevState)
                        }
                        label={
                          pnType === PN_TYPE.SERVICE
                            ? t("newMaterialForm.confidentialService")
                            : t("newMaterialForm.confidentialMaterial")
                        }
                      />
                    </div>

                    <div className="col-span-4">
                      <hr />
                      <div className="w-full uppercase block text-xs font-bold text-gray-900 py-2">
                        {t("certifications.certifications")}
                      </div>
                      {!addCertification && (
                        <button
                          type="button"
                          className="flex items-center gap-2 py-2 px-4 mt-2 border border-gray-900 shadow-sm text-xs font-medium rounded text-gray-900 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light uppercase mr-4"
                          onClick={() => {
                            setAddCertification(!addCertification);
                          }}
                        >
                          <PlusIcon className="h-4 w-4" />

                          {t("certifications.addCertifications")}
                        </button>
                      )}
                      {addCertification && (
                        <>
                          <div className="grid grid-cols-4 gap-2 items-end">
                            <div className="col-span-4 md:col-span-1">
                              <label
                                htmlFor="certificationType"
                                className="uppercase block text-xs font-medium text-gray-700"
                              >
                                {t("certifications.certificationType")}
                              </label>
                              <select
                                id="certificationType"
                                name="certificationType"
                                className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs rounded"
                                value={certificationType?.type}
                                onChange={(e) => {
                                  setCertificationType(
                                    CERTIFICATIONS[
                                      e.target
                                        .value as keyof typeof CERTIFICATIONS
                                    ] as {
                                      type: CertificationsEnum;
                                      label: string;
                                    }
                                  );
                                }}
                              >
                                <option value="">
                                  {t("certifications.selectCertificationType")}
                                </option>
                                {Object.keys(CERTIFICATIONS).map((key) => (
                                  <option
                                    key={key}
                                    value={
                                      CERTIFICATIONS[
                                        key as keyof typeof CERTIFICATIONS
                                      ].type
                                    }
                                  >
                                    {
                                      CERTIFICATIONS[
                                        key as keyof typeof CERTIFICATIONS
                                      ].label
                                    }
                                  </option>
                                ))}
                              </select>
                            </div>

                            {certificationType && (
                              <div className="col-span-4 md:col-span-1">
                                <div>
                                  <label
                                    htmlFor="certificationType"
                                    className="uppercase block text-xs font-medium text-gray-700"
                                  >
                                    {t("certifications.certificationName")}
                                  </label>
                                  <input
                                    type="text"
                                    value={certificationName}
                                    onChange={(e) =>
                                      setCertificationName(e.target.value)
                                    }
                                    list="suggestions"
                                    className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs rounded"
                                    placeholder={t(
                                      "certifications.insertCertificationName"
                                    )}
                                  />
                                  <datalist id="suggestions">
                                    {certificationSuggestions[
                                      certificationType?.type
                                    ].map((suggestion) => (
                                      <option
                                        key={suggestion}
                                        value={suggestion}
                                      />
                                    ))}
                                  </datalist>
                                </div>
                              </div>
                            )}

                            {certificationType && (
                              <div className="col-span-4 md:col-span-1 flex items-center">
                                <Toggle
                                  enabled={certificationRequired}
                                  onChange={() =>
                                    setCertificationRequired(
                                      (prevState) => !prevState
                                    )
                                  }
                                  label={t(
                                    "certifications.certificationRequired"
                                  )}
                                />
                                <button
                                  type="button"
                                  className="ml-6 flex items-center gap-2 justify-center py-2 px-4 border border-transparent shadow-sm text-xs font-medium rounded text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light uppercase"
                                  onClick={() => {
                                    onAddCertification();
                                    setAddCertification(false);
                                  }}
                                >
                                  <PlusIcon className="h-5 w-5" />
                                  {i18next.t("cta.add")}
                                </button>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                      {certifications.length > 0 && (
                        <div className="mt-4">
                          <ul className="grid grid-cols-4 gap-2">
                            {certifications.map((certification, index) => (
                              <li
                                key={index}
                                className="col-span-1 shadow-sm sm:text-xs border-gray-300 flex text-xs border rounded items-center justify-between p-2"
                              >
                                <span>
                                  {certification.name} ({certification.label}){" "}
                                  {certification.required && (
                                    <span className="text-red-500 text-md">
                                      *
                                    </span>
                                  )}
                                </span>
                                <XMarkIcon
                                  className="h-5 w-5 text-red-600 cursor-pointer border rounded-full border-red-500"
                                  onClick={() => onRemoveCertification(index)}
                                />
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>

                    <div className="col-span-4">
                      <hr />
                    </div>

                    {pnType !== PN_TYPE.SERVICE && (
                      <div className="col-span-4">
                        <div className="flex gap-4 items-center">
                          <label className="uppercase block text-xs font-medium text-gray-700">
                            {t("newMaterialForm.specification")}
                          </label>
                        </div>
                        <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded">
                          <div className="space-y-1 text-center">
                            <svg
                              className="mx-auto h-12 w-12 text-gray-400"
                              stroke="currentColor"
                              fill="none"
                              viewBox="0 0 48 48"
                              aria-hidden="true"
                            >
                              <path
                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            {fileName && <span>{fileName}</span>}

                            <div className="text-xs text-gray-600">
                              <label
                                htmlFor="specifications"
                                className="uppercase relative cursor-pointer bg-white rounded font-medium text-spectum hover:text-spectum-light focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-spectum-light"
                              >
                                {isLoadingUpload ? (
                                  <div className="flex w-full items-center justify-center">
                                    <ClipLoader loading size={30} />
                                  </div>
                                ) : (
                                  <span>{t("newMaterialForm.upload")}</span>
                                )}
                                <input
                                  {...register("specifications")}
                                  id="specifications"
                                  name="specifications"
                                  type="file"
                                  className="sr-only"
                                  onChange={(e) => handleSpecification(e)}
                                />
                              </label>
                            </div>

                            <p className="text-xs text-gray-500">PDF 10MB</p>
                          </div>
                        </div>

                        {errors?.specifications && (
                          <span className="text-xs text-red-500">
                            {errors?.specifications?.message as string}
                          </span>
                        )}
                      </div>
                    )}

                    {pnType !== PN_TYPE.SERVICE && (
                      <div className="col-span-4">
                        <Toggle
                          enabled={isSpecConfidential}
                          onChange={() =>
                            setIsSpecConfidential((prevState) => !prevState)
                          }
                          label={t("newMaterialForm.confidential")}
                        />
                      </div>
                    )}

                    <div className="col-span-4">
                      <InputField
                        id="comments"
                        name="comments"
                        type="text"
                        label={t("newMaterialForm.comments")}
                        register={register}
                        isOptional
                      />
                    </div>
                  </Fragment>
                )}
              </div>
              {/* {materialError?.message && (
                <div className="p-2 mt-2">
                  <p className="text-xs border rounded bg-red-300 text-red-900 p-2">
                    {t("newMaterialForm.repeated")}
                  </p>
                </div>
              )} */}
            </div>
            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <Link
                to="/materials"
                className="inline-flex justify-center py-2 px-4 border border-spectum shadow-sm text-xs font-medium rounded text-gray-700 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light uppercase mr-4"
              >
                {t("cta.cancel")}
              </Link>
              <button
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-xs font-medium rounded text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light uppercase"
                onClick={(e) => {
                  e.preventDefault();
                  setOpen(true);
                }}
                disabled={!pnType}
              >
                {t("cta.save")}
              </button>
            </div>
          </div>
        </div>
        <Modal
          open={open}
          setOpen={setOpen}
          icon={
            <CheckCircleIcon
              className="h-6 w-6 text-green-600"
              aria-hidden="true"
            />
          }
          title={t("newMaterialForm.save")}
          message={t("newMaterialForm.saveQuestion")}
          onClickSuccess={handleSubmit(onSubmit, onError)}
          onClickCancel={() => setOpen(false)}
        />
      </form>
    </Fragment>
  );
};

export default NewMaterialForm;
