import { ChangeEvent, useState } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { PencilIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "i18next";
import { Loader } from "../../components";
import {
  changePasswordSchema,
  mapDispatchToProps,
  mapStateToProps,
} from "../../utils";
import { userCompanyTypeMap, UserResponse } from "../../types";
import { useChangeLanguage, useChangePassword } from "../../hooks/useUsers";

type FormValues = {
  password: string;
  confirmPassword?: string;
};

type ProfileProps = {
  user: UserResponse;
  isRequester: boolean;
  isAdmin: boolean;
  isLoading: boolean;
  isBuyer: boolean;
  isManager: boolean;
  isAnalyst: boolean;
  isVisualizer: boolean;
  isResetPassword: boolean;
  onOpenNotification: (message: string) => void;
  onGetUser: (userId: string) => void;
  userId: string;
  onChangeUserLanguage: any;
  isPendingAdminApproval: boolean;
  isCommercial: boolean;
};

const Profile = ({
  user,
  userId,
  isLoading,
  onGetUser,
  isResetPassword,
  onOpenNotification,
}: ProfileProps) => {
  const { t } = useTranslation();
  const [language, setLanguage] = useState<string>(user?.localization);
  const [change, setChange] = useState(false);
  const navigate = useNavigate();
  const formOptions = { resolver: yupResolver(changePasswordSchema) };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>(formOptions);

  const { mutate: changePassword, isPending } = useChangePassword(
    () => {
      onOpenNotification(t("login.change"));
      onGetUser(userId);
      if (isResetPassword) navigate("/");
    },
    () => onOpenNotification(t("newMaterialForm.error"))
  );

  const { mutate: updateLanguage, isPending: isChangingLanguage } =
    useChangeLanguage(
      () => {
        onOpenNotification(t("login.change"));
      },
      () => onOpenNotification(t("newMaterialForm.error"))
    );

  const onSubmit = (data: FormValues) => {
    changePassword({ password: data.password });
    setChange(false);
  };

  const handleChangeLanguage = async () => {
    updateLanguage({ userId, language });
    changeLanguage(language);
  };

  if (isPending || isChangingLanguage) return <Loader />;

  return (
    <div className="px-4 mt-6">
      {!isResetPassword ? (
        <>
          <h2 className="text-xl font-bold leading-7 text-gray-900 sm:truncate uppercase">
            {t("profile.title")}
          </h2>
          <div className="bg-white shadow overflow-hidden sm:rounded mt-6">
            <div className="px-4 py-5 sm:px-6 flex items-center justify-between">
              <h3 className="text-sm leading-6 font-medium text-gray-900 uppercase">
                {t("profile.information")}
              </h3>
              <Link
                type="button"
                to={`/user/edit/${userId}`}
                className="uppercase inline-flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light"
              >
                <PencilIcon
                  className="h-4 w-4 text-gray-400"
                  aria-hidden="true"
                />
              </Link>
            </div>
            {isLoading ? (
              <div className="py-2">
                <Loader />
              </div>
            ) : (
              <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200">
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-xs font-medium text-gray-500 uppercase">
                      {t("profile.name")}
                    </dt>
                    <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                      {user?.fullName}
                    </dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-xs font-medium text-gray-500 uppercase">
                      {t("profile.role")}
                    </dt>
                    <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                      {
                        userCompanyTypeMap[
                          user?.userCompanyType as keyof typeof userCompanyTypeMap
                        ]
                      }
                    </dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-xs font-medium text-gray-500 uppercase">
                      {t("profile.company")}
                    </dt>
                    <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                      {user?.company?.name}
                    </dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-xs font-medium text-gray-500 uppercase">
                      {t("profile.email")}
                    </dt>
                    <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                      {user?.email}
                    </dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-xs font-medium text-gray-500 uppercase">
                      {t("profile.phone")}
                    </dt>
                    <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                      {user?.phone}
                    </dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-xs font-medium text-gray-500 uppercase">
                      {t("profile.password")}
                    </dt>
                    <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                      <button
                        onClick={() => setChange((prevState) => !prevState)}
                        className="uppercase mr-4 px-4 py-2 border border-gray-300 shadow-sm text-xs font-medium rounded text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light"
                      >
                        {t("profile.changePassword")}
                      </button>
                    </dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <div className="text-xs font-medium text-gray-500 uppercase">
                      {t("profile.language")}
                    </div>
                    <select
                      className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs rounded"
                      onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                        setLanguage(e.target.value)
                      }
                      defaultValue={language}
                    >
                      <option value="en" selected={language === "en"}>
                        English
                      </option>
                      <option value="es" selected={language === "es"}>
                        Español
                      </option>
                      <option value="pt" selected={language === "pt"}>
                        Português
                      </option>
                    </select>
                    <button
                      disabled={language === user?.localization}
                      onClick={handleChangeLanguage}
                      className={`w-fit uppercase px-4 py-2 border border-gray-300 shadow-sm text-xs font-medium rounded focus:outline-none focus:ring-2 focus:ring-offset-2  ${language === user?.localization ? "bg-gray-400 text-gray-700" : "text-white focus:ring-spectum-light bg-spectum hover:bg-spectum-light"}`}
                    >
                      {t("profile.changeLanguage")}
                    </button>
                  </div>
                </dl>
              </div>
            )}
          </div>
        </>
      ) : null}
      <div className="pt-6">
        {change || isResetPassword ? (
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="p-2 border shadow rounded"
          >
            <h3 className="uppercase text-sm text-spectum-dark font-bold">
              {t("profile.changePassword")}
            </h3>

            <div className="border rounded shadow p-2 mt-4">
              <p className="text-xs text-gray-600">
                {t("changePassword.title")}
              </p>
              <ul className="text-xs text-gray-600 list-disc">
                <li className="ml-4">{t("changePassword.characters")}</li>
                <li className="ml-4">{t("changePassword.uppercase")}</li>
                <li className="ml-4">{t("changePassword.lowercase")}</li>
                <li className="ml-4">{t("changePassword.number")}</li>
                <li className="ml-4">{t("changePassword.special")}</li>
              </ul>
            </div>

            <div className="py-4">
              <input
                {...register("password", { required: true })}
                id="password"
                name="password"
                type="password"
                placeholder={t("changePassword.new")}
                className="uppercase mt-1 focus:ring-spectum-light focus:border-spectum-light block w-full shadow-sm sm:text-xs border-gray-300 rounded"
              />
              {errors?.password && (
                <span className="text-xs text-red-500">
                  {errors?.password?.message}
                </span>
              )}
            </div>

            <div className="pb-4">
              <input
                {...register("confirmPassword", { required: true })}
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                placeholder={t("changePassword.confirm")}
                className="uppercase mt-1 focus:ring-spectum-light focus:border-spectum-light block w-full shadow-sm sm:text-xs border-gray-300 rounded"
              />
              {errors?.confirmPassword && (
                <span className="text-xs text-red-500">
                  {errors?.confirmPassword?.message}
                </span>
              )}
            </div>

            <button className="uppercase mr-4 px-4 py-2 border border-gray-300 shadow-sm text-xs font-medium rounded text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light">
              {t("cta.save")}
            </button>
            <button
              onClick={() => setChange(false)}
              className="uppercase px-4 py-2 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light"
            >
              {t("cta.cancel")}
            </button>
          </form>
        ) : null}
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
